// src/PhotoGallery.js
import React, {useEffect,useState} from 'react';
import { Box, Image, SimpleGrid } from '@chakra-ui/react';


const photos = [
  // Tambahkan URL atau path foto-foto Anda di sini
//'https://res.cloudinary.com/dni0gpltq/image/upload/v1718419879/Aqshal_js2i3j.jpg',
'https://res.cloudinary.com/dni0gpltq/image/upload/v1719376141/20240524_073022_wkb49w.webp',
'https://res.cloudinary.com/dni0gpltq/image/upload/v1719375425/20240516_154001_puaptn.jpg',
//'https://res.cloudinary.com/dni0gpltq/image/upload/v1718419879/Aqshal_js2i3j.jpg',
//'https://res.cloudinary.com/dni0gpltq/image/upload/v1719375425/20240516_154001_puaptn.jpg',
//'https://res.cloudinary.com/dni0gpltq/image/upload/v1719376141/20240524_073022_wkb49w.webp',
  // Tambahkan lebih banyak URL foto di sini
];



const PhotoGallery = () => {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const calculateParallax = (value, ratio) => {
      return -value * ratio + 'px';
    };
  return (
    <Box
    position="fixed"
    top="0"
    left="0"
    width="100%"
    height="100%"
    zIndex="-1"
    overflow="hidden"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <SimpleGrid columns={[1]}  width="100%" height="100%">
      {photos.map((photo, index) => (
        <Box key={index} width="100%" height="100%"
        
        transform={`translateY(${calculateParallax(scrollPosition, 8.0)})`}
            transition="transform 0.10s">
            
          <Image
            src={photo}
            alt={`Photo ${index + 1}`}
            objectFit="cover"
            width="100%"
            height="100%"
            filter={"brightness(0.15)"}
          />
         
        </Box>
      ))}
    </SimpleGrid>
  </Box>
  );
};

export default PhotoGallery;
