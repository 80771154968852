import React, { useRef } from 'react';
import {
  Box, Heading, Text, VStack, Button, HStack, Image, Grid,
  GridItem,
  Divider,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import { Link } from 'react-router-dom';

const Resume = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box p={5}>
      <HStack justify="center" mt={5}>
      <Link to='/'>
      <Button colorScheme="teal">Back to Home</Button>
    </Link>
        <Button colorScheme="blue" onClick={handlePrint}>Download as PDF</Button>
      </HStack>
      <CV ref={componentRef} />
    </Box>
  );
};


const CV = React.forwardRef((props, ref) => (

<Box ref={ref} p={5} borderWidth={2} borderRadius="md" width={'auto'}  mt={5} >
    <VStack align="start" spacing={3}>
    <Grid templateColumns='repeat(2, 1fr)' gap={2}>
    <GridItem>
    <Image src="https://res.cloudinary.com/dni0gpltq/image/upload/v1718419879/Aqshal_js2i3j.jpg" alt="Profile Aqshal Alukalfauzan" title='Aqshal Alukalfauzan' width={'100px'} height={'auto'}/>
    </GridItem>
    <GridItem>
    <Heading as="h1" size="xl" align='center'>Aqshal Alukalfauzan</Heading>
      <Text fontSize={18} textAlign={'center'}>On-site Engineer | Informatics Engineering</Text>
      <Text as={'p'} size={'sm'} align={'center'} mt={5}>Email : contact@aqshalfauzan10.com</Text>
    </GridItem>
      </Grid>

      <Grid templateColumns='repeat(1, 1fr)'>
        <Heading as={'h2'} size={'lg'} mb={2}>Summary</Heading>
      <Divider mt={3} mb={3} />
        <Text>
        Results-driven On-Site Engineer with 1 years of experience in delivering comprehensive technical support and ensuring optimal performance of IT infrastructure for clients. Proficient in diagnosing and resolving hardware and software issues, implementing system upgrades, and maintaining detailed documentation. Proven ability to collaborate effectively with cross-functional teams and communicate technical information clearly to both technical and non-technical stakeholders.
        </Text>
        </Grid>

      <Heading as="h2" size="lg" mt={5}>Experience</Heading>

      <Divider mt={3} mb={3} />

        <Box>
        <Grid templateColumns='repeat(2, 3fr)' gap={2}>
        <GridItem>
        <Text fontWeight="bold">Elang Strategi Adidaya Corp. </Text> 
        <Text>On-site Engineer</Text>
          </GridItem>
          <GridItem>
          <Text p={2} align={'end'}>April 2023 - Present</Text>
          </GridItem>
          </Grid>
          <UnorderedList>
            <ListItem>To maintain software and hardware to ensure it will be running properly in clients.</ListItem>

          </UnorderedList>
        </Box>

      <Heading as="h2" size="lg" mt={5}>Education</Heading>
      <Divider mt={3} mb={3} />
      <VStack align="start" spacing={2}>
        <Box>
          <Text fontWeight="bold">D3 Informatics Engineering</Text>
          <Text>Sekolah Tinggi Teknologi Mandala - 2023</Text>
        </Box>
      </VStack>

      <Heading as="h2" size="lg" mt={5}>Skills</Heading>
      <Divider mt={3} mb={3} />
      <VStack align="start" spacing={2}>
        <Text>vMix, OBS Studio, React, Chakra UI, etc.</Text>
      </VStack>
    </VStack>
  </Box>
));


export default Resume;
