import React from 'react';
import {ChakraProvider, Container, Box} from '@chakra-ui/react';
import { useEffect } from 'react';
import Footer from './Footer';
import PhotoGallery from './Gallery';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ProfileBox from './ProfileBox';
import Resume from './Resume';
import NotFound from './NotFound';

function App() {
  
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    
    window.addEventListener('contextmenu', handleContextMenu);
    
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    
    <ChakraProvider>
      <Helmet>

<title>Aqshal Alukalfauzan</title>
        <meta name="description" content="This is my personal website, blogs and portfolio."/>
          
          <meta property="og:url" content="https://aqshalfauzan10.com"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Aqshal Alukalfauzan"/>
                <meta property="og:description" content="This is my personal website, blogs and portfolio."/>
<meta property="og:image" content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v4/aqshalfauzan10.com/Aqshal%20Alukalfauzan/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2Fdf594265-0a1c-43ef-8ae4-28d85a2c8559.png%3Ftoken%3DuNS5tFRNzScbzKoNiumhbSB1Y0pOQVB-_6fwMCszrVU%26height%3D1000%26width%3D1000%26expires%3D33261780031/og.png"></meta>
     <link rel="icon" href="https://res.cloudinary.com/dni0gpltq/image/upload/v1718420292/favicon_ha6lhh.ico" />
     
        <meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="aqshalfauzan10.com"/>
<meta property="twitter:url" content="https://aqshalfauzan10.com"/>
<meta name="twitter:title" content="Aqshal Alukalfauzan"/>
        <meta name="twitter:description" content="This is my personal website, blogs and portfolio."></meta>
        
        <meta name="twitter:image" content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v4/aqshalfauzan10.com/Aqshal%20Alukalfauzan/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fimages%2Fdf594265-0a1c-43ef-8ae4-28d85a2c8559.png%3Ftoken%3DuNS5tFRNzScbzKoNiumhbSB1Y0pOQVB-_6fwMCszrVU%26height%3D1000%26width%3D1000%26expires%3D33261780031/og.png"></meta>

        
        <meta charset="utf-8" />
    
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <link rel="apple-touch-icon" href="https://res.cloudinary.com/dni0gpltq/image/upload/v1718420292/apple-touch-icon_wbhxkt.png" />
     
      </Helmet>
      <Box position="relative" minHeight="100vh"
      >
        <Box position="relative" zIndex="2" color="gray" p={2}>
        <PhotoGallery/>
      <Container centerContent>
      <Router>

      <Routes>
        <Route path="/" element={<ProfileBox />} />
        <Route path='/resume' element={<Resume/>}/>

        <Route path='*' Component={NotFound} />
      </Routes>
</Router>
        <Footer/>
      </Container>
      </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
