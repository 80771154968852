import React from 'react';
import { Box, Flex, IconButton, useColorMode, useColorModeValue, Link, useToast, Text} from '@chakra-ui/react';
import { FaSun, FaMoon } from 'react-icons/fa';



const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue('white.100', 'white.900');
  const color = useColorModeValue('white', 'white');
  const toast = useToast()
  return (
    <Box bg={bg} color={color} px={4} position={'sticky'} zIndex={1000}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'} paddingBottom={10}>
        <Link href='/'>
        {/* <Text fontSize="xl" fontWeight="bold">
          Aqshal Alukalfauzan
        </Text> */}
        </Link>
        <Flex alignItems={'center'}>
          <Text paddingRight={2} mt={10}> 
            Change Theme
            </Text>
          <IconButton
          mt={10}
            size="md"
            icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
            aria-label={'Toggle Color Mode'}
            onClick={() =>
                toggleColorMode() &
                toast({
                    title: 'Tema Diubah',
                    description: "Berhasil mengubah warna tema",
                    status: 'success',
                    duration: 1700,
                    isClosable: true,
                  }) 
                }
                
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
