import React from 'react';
import {Box, Center, Heading, VStack, Image, HStack, IconButton, Card, CardHeader, Button, CardFooter, Text, Link, Divider, Icon} from '@chakra-ui/react';
import { FaTwitter, FaInstagram, FaLinkedin, FaTelegram, FaBlogger} from 'react-icons/fa';
import { MdMail, MdWavingHand } from 'react-icons/md';
import Header from './Header';


const ProfileBox = () => {
  return (
    <Box p={10} borderRadius="xl" w="auto">
      <Header/>
     <Card
      paddingTop={5}
      boxShadow={'xl'}       
      alignItems="center"
      justifyContent="center"
      >
      <CardHeader mt={5}>
      <Center> 
        <Image boxSize='auto' src="https://res.cloudinary.com/dni0gpltq/image/upload/v1718419879/Aqshal_js2i3j.jpg" alt="Profile Aqshal Alukalfauzan" title='Aqshal Alukalfauzan' rounded={300}/>
      </Center>
  </CardHeader>
      <VStack mt={4} spacing={4} align="center">
        <Heading as="h2" size="lg" align='center'>Aqshal Alukalfauzan</Heading>
        <Text align='center' >On-site Engineer | Elang Strategi Adidaya Corp.</Text>
        <Text as={'b'} align={'center'} mt={3}>My passion</Text>
        <Text wordBreak={'break-word'} textAlign={'center'}>Photography and Videography | Tech Enthusiast | Author</Text>
     <Divider />
        <Heading as="h4" size="sm" mt={'5'}>Social Media </Heading>
        <HStack spacing={4}align={'stretch'} >
        <Link href='https://blog.aqshalfauzan10.com' target='_blank' title='Blog Aqshal Alukalfauzan'>
          <IconButton icon={<FaBlogger />} rounded={300} aria-label="Blogger" size={'lg'}  />
          </Link>
          <Link href='https://instagram.com/aqshalfauzan10' target='_blank' title='Instagram'>
          <IconButton icon={<FaInstagram />} rounded={300} aria-label="Instagram" size={'lg'}  />
          </Link>
          <Link href='https://x.com/aqshalfauzan10' target='_blank' title='Twitter'>
          <IconButton icon={<FaTwitter />} rounded={300} aria-label="Twitter" size={'lg'}  />
          </Link>
          <Link href='https://linkedin.com/in/aqshalfauzan10' target='_blank' title='Linkedin' >
          <IconButton icon={<FaLinkedin />} rounded={300} aria-label="Linkedin" size={'lg'} />
          </Link>
          <Link href='https://t.me/aqshalfauzan10' target='_blank' title='Telegram'>
          <IconButton icon={<FaTelegram />} rounded={300} aria-label="Telegram" size={'lg'} />
          </Link>
        </HStack>
        <HStack marginTop={5}>
          <Link href='mailto:contact@aqshalfauzan10' target='_blank' alt='Email'>
          <Button leftIcon={<MdMail/>} rounded={300} size={'lg'}>E-mail</Button>
          </Link>
{/*            <Link href='/resume' alt='Curriculum Vitae'>
          <Button leftIcon={<MdFilePresent/>} rounded={300} size={'lg'}>CV</Button>
          </Link> */}
        </HStack>
      </VStack>
      <CardFooter justifyContent={'center'}>
        <Text fontSize="md" fontWeight="semibold" marginTop={'10'}>Thank you for coming! <Icon as={MdWavingHand}/></Text>
      </CardFooter>
    </Card>
    </Box>
    
  );
};

export default ProfileBox;
