import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

const Footer = () => {
  const bg = useColorModeValue('white.100', 'white.900');
  const color = useColorModeValue('white', 'white');
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <Box bg={bg} color={color} py={2}>
      <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
        <Text fontSize="lg" align={'center'} mb={2}>© {currentYear} Aqshal Alukalfauzan</Text>
        {/* <Flex>
          <Link href="/about" mx={2}>About</Link>
          <Link href="/contact" mx={2}>Contact</Link>
          <Link href="/privacy" mx={2}>Privacy Policy</Link>
        </Flex> */}
      </Flex>
    </Box>
  );
};

export default Footer;
